import React, { Component } from 'react'
import { StaticImage } from "gatsby-plugin-image"

class Navbar extends Component {

  componentDidMount(){
    const M = require("materialize-css");
    var sidenav = document.querySelectorAll('.sidenav');
    M.Sidenav.init(sidenav, {});
  }

  render() {
    return (
      <>
        <nav className="nav-wrapper hide-on-small-only show-on-medium-and-up" >
        <div className="brand-logo row">
          <div className="col s2">
            <StaticImage src="../images/icon-blank.webp" alt="TemporadApp logo" />
          </div>
          <div className="col s10">
            <h1>TemporadApp</h1>
          </div>
             
        </div>
        </nav> 
        <nav id="mobile-nav" className="nav-wrapper show-on-down hide-on-med-and-up" >
          <div className="brand-logo left row">
            <div className="col s4">
              <StaticImage className="materialboxed z-depth-2" src="../images/icon-blank.webp" alt="TemporadApp logo" />
            </div>
            <div className="col s8" >
              <h1>TemporadApp</h1>
            </div>
          </div>
        </nav>
      </>
    )
  }
}

export default Navbar